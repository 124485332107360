<template>
    <div>notices</div>
</template>
<script>

import {Vue, Component} from 'vue-property-decorator';
import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
import store from '@/store/store';

@Component
export default class Notices extends Vue {


    async beforeRouteEnter(to, from, next) {
        store.commit('addBreadcrumb', Breadcrumb.create('Notices', null, true));
        next();
    }
}
</script>
<style scoped>

</style>